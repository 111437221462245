const i18n = {
  'en-US': {
    'login.form.title': 'Login to Arco Design Pro',
    'login.form.userName.errMsg': 'Username cannot be empty',
    'login.form.password.errMsg': 'Password cannot be empty',
    'login.form.login.errMsg': 'Login error, please refresh and try again',
    'login.form.userName.placeholder': '',
    'login.form.password.placeholder': '',
    'login.form.rememberPassword': 'Remember password',
    'login.form.forgetPassword': 'Forgot password',
    'login.form.login': 'login',
    'login.form.register': 'register account',
    'login.banner.slogan1': 'Out-of-the-box high-quality template',
    'login.banner.subSlogan1':
      'Rich page templates, covering most typical business scenarios',
    'login.banner.slogan2': 'Built-in solutions to common problems',
    'login.banner.subSlogan2':
      'Internationalization, routing configuration, state management everything',
  },
  'zh-CN': {
    'login.form.title': '登录',
    'login.form.userName.errMsg': '用户名不能为空',
    'login.form.password.errMsg': '密码不能为空',
    'login.form.login.errMsg': '登录出错，请刷新重试',
    'login.form.userName.placeholder': '请输入用户名',
    'login.form.password.placeholder': '请输入密码',
    'login.form.rememberPassword': '记住密码',
    'login.form.forgetPassword': '忘记密码',
    'login.form.login': '登录',
    'login.form.register': '注册账号',
    'login.banner.slogan1': '没有文案',
    'login.banner.subSlogan1': '',
    'login.banner.slogan2': '别看了第二页也没有文案',
    'login.banner.subSlogan2': '',
  },
};

export default i18n;
