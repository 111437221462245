/* eslint-disable */
import request from './request';

/**
 * 登录
 *
 * @param data
 * @returns
 */
export async function auth(data: any) {
  return request('/backend/login', {
    data,
    method: 'post',
  });
}

/**
 * 获取个人资料
 *
 * @param data
 * @returns
 */
export async function profile(data: any) {
  return request('/backend/profile', {
    data,
    method: 'get',
  });
}

/**
 * 修改个人资料
 *
 * @param data
 * @returns
 */
export async function profileUpdate(data: any) {
  return request('/backend/profile/update', {
    data,
    method: 'post',
  });
}
/**
 * 修改系统设置
 *
 * @param data
 * @returns
 */
export async function systemSettingUpdate(data: any) {
  return request('/backend/systemSetting/update', {
    data,
    method: 'post',
  });
}

/**
 * 获取系统设置
 *
 * @param data
 * @returns
 */
export async function systemSettingGet(data: any) {
  return request('/backend/systemSetting/get', {
    data,
    method: 'post',
  });
}

/**
 * 获取OSS配置
 *
 * @param data
 * @returns
 */
export async function commonOssStsTokenGet(data: any) {
  return request('/backend/oss/stsToken', {
    data,
    method: 'get',
  });
}

/**
 * 获取角色列表
 *
 * @param data
 * @returns
 */
export async function roleList(data: any) {
  return request('/backend/role/list', {
    data,
    method: 'get',
  });
}
/**
 * 获取角色列表(分页)
 *
 * @param data
 * @returns
 */
export async function rolePaginate(data: any) {
  return request('/backend/role/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 修改或编辑角色
 *
 * @param data
 * @returns
 */
export async function roleUpdateOrCreate(data: any) {
  return request('/backend/role/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除角色
 *
 * @param data
 * @returns
 */
export async function roleDelete(data: any) {
  return request('/backend/role/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取角色权限列表
 *
 * @param data
 * @returns
 */
export async function rolePermissionList(data: any) {
  return request('/backend/role/permission/list', {
    data,
    method: 'get',
  });
}

/**
 * 获取用户列表
 *
 * @param data
 * @returns
 */
export async function userPaginate(data: any) {
  return request('/backend/user/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取用户列表
 *
 * @param data
 * @returns
 */
export async function userList(data: any) {
  return request('/backend/user/list', {
    data,
    method: 'get',
  });
}

/**
 * 修改或编辑用户
 *
 * @param data
 * @returns
 */
export async function userUpdateOrCreate(data: any) {
  return request('/backend/user/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除用户
 *
 * @param data
 * @returns
 */
export async function userDelete(data: any) {
  return request('/backend/user/delete', {
    data,
    method: 'post',
  });
}

/**
 * 编辑用户备注
 *
 * @param data
 * @returns
 */
export async function userUpdateRemark(data: any) {
  return request('/backend/user/updateRemark', {
    data,
    method: 'post',
  });
}

/**
 * 获取会员列表
 *
 * @param data
 * @returns
 */
export async function memberPaginate(data: any) {
  return request('/backend/member/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取会员列表
 *
 * @param data
 * @returns
 */
export async function memberDetail(data: any) {
  return request('/backend/member/detail', {
    data,
    method: 'get',
  });
}
/**
 * 获取会员列表
 *
 * @param data
 * @returns
 */
export async function memberSetMechanism(data: any) {
  return request('/backend/member/set/mechanism', {
    data,
    method: 'post',
  });
}

/**
 * 获取会员列表
 *
 * @param data
 * @returns
 */
export async function memberSetAdzoneId(data: any) {
  return request('/backend/member/set/adzoneId', {
    data,
    method: 'post',
  });
}

/**
 * 获取会员列表
 *
 * @param data
 * @returns
 */
export async function memberSetMaxUsage(data: any) {
  return request('/backend/member/set/resourceMaxUsage', {
    data,
    method: 'post',
  });
}
/**
 * 获取会员列表
 *
 * @param data
 * @returns
 */
export async function memberSetLevelId(data: any) {
  return request('/backend/member/set/levelId', {
    data,
    method: 'post',
  });
}

/**
 * 获取会员广告查看统计数据
 *
 * @param data
 * @returns
 */
export async function memberAdVisitCharts(data: any) {
  return request('/backend/member/adVisitCharts', {
    data,
    method: 'get',
  });
}

/**
 * 获取QA列表
 *
 * @param data
 * @returns
 */
export async function qaPaginate(data: any) {
  return request('/backend/qa/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 创建和修改QA
 *
 * @param data
 * @returns
 */
export async function qaUpdateOrCreate(data: any) {
  return request('/backend/qa/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除QA
 *
 * @param data
 * @returns
 */
export async function qaDelete(data: any) {
  return request('/backend/qa/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取CP语录列表
 *
 * @param data
 * @returns
 */
export async function cpWordPaginate(data: any) {
  return request('/backend/cpWord/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 创建和修改CP语录
 *
 * @param data
 * @returns
 */
export async function cpWordUpdateOrCreate(data: any) {
  return request('/backend/cpWord/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除CP语录
 *
 * @param data
 * @returns
 */
export async function cpWordDelete(data: any) {
  return request('/backend/cpWord/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取会员等级列表
 *
 * @param data
 * @returns
 */
export async function memberLevelList(data: any) {
  return request('/backend/memberLevel/list', {
    data,
    method: 'get',
  });
}

/**
 * 创建和修改会员等级
 *
 * @param data
 * @returns
 */
export async function memberLevelUpdateOrCreate(data: any) {
  return request('/backend/memberLevel/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除会员等级
 *
 * @param data
 * @returns
 */
export async function memberLevelDelete(data: any) {
  return request('/backend/memberLevel/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取API请求统计
 *
 * @param data
 * @returns
 */
export async function apiChartsList(data: any) {
  return request('/backend/apiCharts/list', {
    data,
    method: 'get',
  });
}

/**
 * 获取钱包流水记录
 *
 * @returns
 */
export async function walletRecordPaginate(data: any) {
  return request('/backend/walletRecord/paginate', {
    data,
    method: 'get',
  });
}
/**
 * 获取钱包列表
 *
 * @returns
 */
export async function walletPaginate(data: any) {
  return request('/backend/wallet/paginate', {
    data,
    method: 'get',
  });
}
/**
 * 钱包充值
 *
 * @returns
 */
export async function walletRecharge(data: any) {
  return request('/backend/wallet/recharge', {
    data,
    method: 'post',
  });
}

/**
 * 获取提现列表
 *
 * @returns
 */
export async function withdrawPaginate(data: any) {
  return request('/backend/withdraw/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 同意提现
 *
 * @returns
 */
export async function withdrawAccept(data: any) {
  return request('/backend/withdraw/accept', {
    data,
    method: 'post',
  });
}

/**
 * 驳回提现
 *
 * @returns
 */
export async function withdrawRefuse(data: any) {
  return request('/backend/withdraw/refuse', {
    data,
    method: 'post',
  });
}

/**
 * 获取广告位数据
 *
 * @param data
 * @returns
 */
export async function adPromotionDataPaginate(data: any) {
  return request('/backend/adSitePromotionData/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 同步昨日数据
 *
 * @param data
 * @returns
 */
export async function adPromotionDataRunner(data: any) {
  return request('/backend/adSitePromotionData/runner', {
    data,
    method: 'post',
  });
}

/**
 * 结算
 *
 * @param data
 * @returns
 */
export async function adPromotionDataSettle(data: any) {
  return request('/backend/adSitePromotionData/settle', {
    data,
    method: 'post',
  });
}

/**
 * 获取网盘资源列表
 *
 * @param data
 * @returns
 */
export async function moviePaginate(data: any) {
  return request('/backend/movie/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取网盘资源列表
 *
 * @param data
 * @returns
 */
export async function movieSearchOptions(data: any) {
  return request('/backend/movie/searchOptions', {
    data,
    method: 'get',
  });
}

/**
 * 创建和修改网盘资源
 *
 * @param data
 * @returns
 */
export async function movieUpdateOrCreate(data: any) {
  return request('/backend/movie/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 创建和修改网盘资源
 *
 * @param data
 * @returns
 */
export async function movieCreateByShareUrl(data: any) {
  return request('/backend/movie/createByShareUrl', {
    data,
    method: 'post',
  });
}

/**
 * 创建和修改网盘资源
 *
 * @param data
 * @returns
 */
export async function movieUpdateTags(data: any) {
  return request('/backend/movie/setTags', {
    data,
    method: 'post',
  });
}

/**
 * 搜索豆瓣资源
 *
 * @param data
 * @returns
 */
export async function movieSearchByDouban(data: any) {
  return request('/backend/movie/douban/search', {
    data,
    method: 'get',
  });
}
/**
 * 豆瓣资源详情
 *
 * @param data
 * @returns
 */
export async function movieDetailByDouban(data: any) {
  return request('/backend/movie/douban/detail', {
    data,
    method: 'get',
  });
}

/**
 * 删除网盘资源
 *
 * @param data
 * @returns
 */
export async function movieDelete(data: any) {
  return request('/backend/movie/delete', {
    data,
    method: 'post',
  });
}

/**
 * 删除网盘资源
 *
 * @param data
 * @returns
 */
export async function movieDetail(data: any) {
  return request('/backend/movie/detail', {
    data,
    method: 'get',
  });
}

/**
 * 网盘合集列表
 *
 * @param data
 * @returns
 */
export async function movieTagPaginate(data: any) {
  return request('/backend/movie/tag/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 网盘合集详情
 *
 * @param data
 * @returns
 */
export async function movieTagDetail(data: any) {
  return request('/backend/movie/tag/detail', {
    data,
    method: 'get',
  });
}

/**
 * 修改或创建合集标签
 *
 * @param data
 * @returns
 */
export async function movieTagUpdateOrCreate(data: any) {
  return request('/backend/movie/tag/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除合集标签
 *
 * @param data
 * @returns
 */
export async function movieTagDelete(data: any) {
  return request('/backend/movie/tag/delete', {
    data,
    method: 'post',
  });
}

/**
 * 删除合集标签中的资源
 *
 * @param data
 * @returns
 */
export async function movieTagMovieRemove(data: any) {
  return request('/backend/movie/tag/movie/remove', {
    data,
    method: 'post',
  });
}

/**
 * 添加合集标签中的资源
 *
 * @param data
 * @returns
 */
export async function movieTagMovieAdd(data: any) {
  return request('/backend/movie/tag/movie/add', {
    data,
    method: 'post',
  });
}
