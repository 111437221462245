export const PermissionUserView = 'user:view';
export const PermissionUserEdit = 'user:edit';
export const PermissionUserDelete = 'user:delete';

export const PermissionRoleView = 'role:view';
export const PermissionRoleEdit = 'role:edit';
export const PermissionRoleDelete = 'role:delete';

export const PermissionConfigView = 'user:config:view'; // 配置查看
export const PermissionConfigEdit = 'user:config:edit'; // 配置编辑

export const PermissionMemberView = 'member:view';
export const PermissionMemberEdit = 'member:edit';
export const PermissionMemberAdVisitView = 'member:adVisit:view';

export const PermissionQAView = 'qa:view';
export const PermissionQAEdit = 'qa:edit';
export const PermissionQADelete = 'qa:delete';

export const PermissionCpWordView = 'cpWord:view';
export const PermissionCpWordEdit = 'cpWord:edit';
export const PermissionCpWordDelete = 'cpWord:delete';
